$(function() {
    // scope script to page with homepage-featured-content container
    var hasFeaturedContent = $('.featured-content-carousel').length ? true : false
    if (!hasFeaturedContent) return;
    
    // container variable for scoping javascript
    var featuredContent = $('.featured-content-carousel ')

    // set timout globally so that you can clear if menuItem is selected
    var imageTimeout

    // set global variable to indicate mobile view
    var mobile = $(window).width() >= 1009 ? false : true;

    // global variable that determines if mobile modal menu is closed
    var mobileMenuHidden

    // boolean indicates whether menu onboarding message has been removed
    var removeOnboarding = false

    // global variable to keep track of current menu item index
    var currentIndex = 0
    


    // display on boarding message after 2 seconds
    setTimeout(function(){ 
        if(removeOnboarding === false){
            $(featuredContent).find('.menu-onboarding-message').removeClass('hide-asset');
        }
    }, 1000)

    // close onboarding message
    $(featuredContent).find('.close-onboarding-container').click(function(){
        closeOnboarding();
        removeOnboarding = true;
    })

    // function to hide onboarding message(can be triggered by onboarding close button or opening the mobile menu)
    function closeOnboarding(){
        $(featuredContent).find('.menu-onboarding-message').addClass('hide-asset');
    }
      
    $(window).resize(function () {
        resizeMenu()
    });

    function resizeMenu(assetHeight) {
        if ($(window).width() >= 1009) {
            mobile = false
            scrollLock(false)
            
        } else {
            mobile = true            
            // if modal is open prevent scrolling
            if(mobileMenuHidden === false){
                scrollLock(true)
            }
        }
    }

    // toggle menu animation in mobile view
    $(featuredContent).find('.mobile-menu-toggle').click(function () {
        toggleSlider();
    })

    // manually select menu item
    $(featuredContent).find('.menu-item').click(function () {
        selectMenuItem(this);

        // only toggle menu in mobile
        if (mobile === true) {
            toggleSlider();
        }

    })

    // locks scroll in mobile when menu is open
    function scrollLock(scrollLock){
        if(scrollLock){
            $('body').css('overflow','hidden');
            $('html').css('overflow','hidden');
        }else{
            $('body').css('overflow','auto');
            $('html').css('overflow','auto');
        }
    }

    function toggleSlider() {
        var hidden = $(featuredContent).find(".inner-menu").hasClass('remove-inner-menu');
        if(hidden===true){
            $(featuredContent).find(".mobile-close-container").removeClass('collapse-menu');
            $(featuredContent).find(".inner-menu").removeClass('remove-inner-menu');
            $(featuredContent).find('.banner-menu').addClass('mobile-menu-open');
            scrollLock(true)
            mobileMenuHidden = false

            removeOnboarding = true
            closeOnboarding();
        }else{
            // play mobile menu collapse animation before removing mobile menu from the DOM.
            $(featuredContent).find(".mobile-close-container").addClass('collapse-menu');
            setTimeout(function(){ 
                $(featuredContent).find(".inner-menu").addClass('remove-inner-menu');
                $(featuredContent).find('.banner-menu').removeClass('mobile-menu-open');
                scrollLock(false)
                mobileMenuHidden = true
            }, 190)
        }
    }

    $(featuredContent).find('.close-modal').click(function() {
        toggleSlider();
      });

    // Mobile specific function, which restarts video when video menu item is selected
    function restartVideo(selection){
        if ($(featuredContent).find(selection).hasClass('video')) {
            selection.currentTime = 0;
            $(featuredContent).find(selection).trigger('play');
            $(featuredContent).find(selection).on('ended', function () {
                selection.currentTime = 0;
                $(featuredContent).find(selection).trigger('play');
            })
        }
    }

    // In desktop check asset type. For images swap on interval - for videos swap on end
    function checkAssetType(selection, elementIndex) {
        var menuItemArray = $(featuredContent).find('.menu-item')
        clearTimeout(imageTimeout)
        if ($(featuredContent).find(selection).hasClass('video')) {
            selection.currentTime = 0
            $(featuredContent).find(selection).trigger('play');
       
            $(featuredContent).find(selection).on('ended', function () {
                if (elementIndex === menuItemArray.length - 1) {
                   elementIndex === currentIndex? selectMenuItem(menuItemArray[0]) : ''
                } else {
                    elementIndex === currentIndex? selectMenuItem(menuItemArray[elementIndex + 1]) : ''
                }
            });
        } else {
            var menuItemArray = $('.menu-item')
            imageTimeout = setTimeout(function () {
                if (elementIndex === menuItemArray.length - 1) {
                    selectMenuItem(menuItemArray[0])
                } else {
                    selectMenuItem(menuItemArray[elementIndex + 1])
                }
            }, 10000);
        }
    }


    // check url params to see which menu item should be selected on load
    if (window.location.search) {
        var queryString = window.location.search
        var selection = parseInt(queryString.charAt(11))
        selectMenuItem($(featuredContent).find('.menu-item')[selection])
    } else {
        selectMenuItem($(featuredContent).find('.menu-item')[0])
    }

    function selectMenuItem(select) {
        var selectionIndex = $(select).index() 
        $(featuredContent).find('li').removeClass('selected');
        $(featuredContent).find(select).toggleClass('selected');
        updateAsset(selectionIndex);
    }

    // update asset and both sets of asset text when menu is clicked
    function updateAsset(select) {
        // selectionType indicates whether asset is video or image
        var selectionType = $(featuredContent).find('.asset')[select]
        var selection = $(featuredContent).find('.item-container')[select]
        // check if menuItem has menutext
        var hasText = $(featuredContent).find(selection).children()[1]
        var mobileTextSelection 
        hasText !== undefined? mobileTextSelection = select : mobileTextSelection = false
        $(featuredContent).find('.item-container').removeClass('selected-asset');
        $(featuredContent).find(selection).addClass('selected-asset');
        updateMobileText(mobileTextSelection)
        currentIndex = select
        if(mobile === false){
            checkAssetType(selectionType, select)
        }else{
            restartVideo(selectionType)
        }
        
        autoScroll(select)
    }

    function autoScroll(index){
        var menu = $(featuredContent).find('.toggle');
        var menuItem = $(featuredContent).find('.selected');
        var menuItemHeight =$(menuItem[0]).height()
        var scrollPosition = menuItemHeight * index
        $(menu).animate({ scrollTop: scrollPosition });
    }
    
    function updateMobileText(select){
        if(select === false){
            $(featuredContent).find('.mobile-text').removeClass('selected-asset');
        }else{
            var mobileTextSelection = $(featuredContent).find("div").find(".mobile-text")[select]
            $(featuredContent).find('.mobile-text').removeClass('selected-asset');
            $(featuredContent).find(mobileTextSelection).addClass('selected-asset'); 
        }
    }

})