$(function () {
    /*Initialize Foundation*/
    $(document).foundation();

    /*Add dynaminc page class*/
    //$(window).on('load', function () {
    var urlPath = window.location.pathname.split('/').filter(function (v) { return v !== '' }).join('-');
    if (urlPath == "") {
        $('body').addClass('homepage');
    }
    else {
        $('body').addClass('body-' + urlPath);
    }
    //})
    /*Add dynaminc page class*/

    $('img.img2svg').img2svg();
    $(window).on('load', function () {
        $('img.img2svg').img2svg();
    });

    /* Page load fix */
    window.onload = new function () {
        $('body').removeClass('page-load');
    }
    /* Page load fix ENDs*/

    /*Hero heding fix*/
    var heroH = $(".hero h1.edge-line");
    heroH.each(function () {
        $(this).html($.trim($(this).html()))
    });

    function heroTxtFix() {
        heroH.parent('.hide-for-small-only').find('h1').each(function () {
            var d = $(this), text = d.html();
            d.parent().width(0);
            d.html('<span>' + text + '</span>');
            width = d.find('span:first').width();
            d.html(text);
            d.parent().width(width)
        })
    }
    heroTxtFix();
    $(window).on('resize', function () { heroTxtFix(); })



    var heroHeadTxt = heroH.text().trim().slice(-1).toLowerCase();
    if (heroHeadTxt == "a" || heroHeadTxt == decodeURIComponent("%C3%A0") || heroHeadTxt == "e" || heroHeadTxt == decodeURIComponent("%C3%A8") || heroHeadTxt == decodeURIComponent("%C3%A9") || heroHeadTxt == decodeURIComponent("%C3%AA") || heroHeadTxt == decodeURIComponent("%C3%AB") || heroHeadTxt == "k" || heroHeadTxt == "r" || heroHeadTxt == "x") {
        heroH.addClass('edge-line-space');
    }
    else if (heroHeadTxt == "t" || heroHeadTxt == "y") {
        heroH.addClass('edge-line-space-less');
    }
    /*Hero heding fix End*/


    /*Buttons*/
    $('.button').each(function () {
        $(this).attr('data-content', $(this).text());
    })
    /*Buttons Ends*/

    /*Search*/
    $('.search, .search input').on('click', function (event) {
        event.stopPropagation();
    });
    $('.search').on('click', function (event) {
        $(this).toggleClass('active');
        $('.search input').focus();
        if (!$(this).hasClass('active')) {
            $('.search input').blur();
        }
    });
    $('body,html').on('click', function () {
        $('.search').removeClass('active');
    });
    /*Search Ends*/

    /*Hamburger menu*/
    $(".hamburger-bar").on("on.zf.toggler", function () {
        $('html,body').addClass('overflow-hidden');
        $('.main-menu li').on('touchend', 'a', function () {
            $('.main-menu').removeClass('active');
        })
    }).on("off.zf.toggler", function () {
        $('html,body').removeClass('overflow-hidden');
    });
    /*Hamburger menu END*/
    /*$('#main-menu li:nth-child(n+6)').prev().addClass('hide-for-large');*/
    /* Small device script */
    function searchAppend() {
        if (Foundation.MediaQuery.current == "medium" || Foundation.MediaQuery.current == "small") {
            $(".main-menu .search").insertAfter('.main-menu a.logo');
        } else {
            $(".main-menu .search").appendTo('#main-menu li:last');
        }
    }
    searchAppend();

    $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
        searchAppend();
    })
    /* Small device script Ends*/


    /*Footer*/
    if (Foundation.MediaQuery.is('small only')) {
        var footLink = $('footer .dropdown-btn');
        footLink.on('click', function () {
            if ($(this).next('ul').is(':visible')) {
                footLink.next('ul').slideUp();
                footLink.removeClass('active');
            } else {
                footLink.next('ul').slideUp();
                $(this).next('ul').slideDown();
                footLink.removeClass('active');
                $(this).addClass('active');
            }
        })
    }
    //Navigation Fix
    var getFCell = $('#footer .large-up-5>.cell');
    var getFCellNum = getFCell.length;
    if (getFCellNum < 5) {
        getFCell.parent().removeClass('large-up-5').addClass('large-up-' + getFCellNum)
    }
    /*Footer Ends*/

    /*Scroll to top when arrow up clicked BEGIN*/
    $(window).scroll(function () {
        var height = $(window).scrollTop();
        if (height > 200) {
            $('#back2Top').fadeIn();
        } else {
            $('#back2Top').fadeOut();
        }
        if ($(window).scrollTop() > ($('#footer').offset().top - $('#footer').height() - 150)) {

            $('#back2Top').addClass('active')
        } else {
            $('#back2Top').removeClass('active')
        }


    });
    $(document).ready(function () {
        $("#back2Top").click(function (event) {
            event.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });

    });
    /*Scroll to top when arrow up clicked END*/

    /*available media */
    if ($('.available-media ul>li').length <= 3) { $('[data-open="available-media-more"]').remove() }
    /*available media end*/

    /*Removed empty space Quote opening Begins*/
    var quoteTag = $('.quote-opening q');
    quoteTag.text($.trim(quoteTag.text()))
    /*Removed empty space Quote opening Ends*/

    if (Foundation.MediaQuery.is('medium only') || Foundation.MediaQuery.is('small only')) {
        $('.top-header .dropdown').foundation('_destroy');
        setTimeout(function () {
            new Foundation.DropdownMenu($('.top-header .dropdown'));
        }, 100)
    }


});