$(function () {
    var videoTag = $('#video')[0];

    //This function for video timeout after displaying hero image
    //function VideoCompletedTime() {
    //    var heroVideoTime = setInterval(function () {
    //        if (Math.round(videoTag.duration) == Math.round(videoTag.currentTime)) {
    //            $('.hero').removeClass('active');
    //            $('.hero').removeAttr('style');
    //            clearInterval(heroVideoTime)
    //        }
    //    })
    //}



 

    $(".play-video").on('click', function () {
        var getHero = $(this).parents('.hero');
        if (videoTag.paused == false) {
            videoTag.pause();
            getHero.removeClass('active');
            getHero.removeAttr('style');
        } else {
            videoTag.play();
            //videoTag.currentTime = '0';
            //getHero.height($('video').height())
            getHero.addClass('active');
        }

        //VideoCompletedTime();
    });

    function videoHeighFix() {
        if ($('.hero video').parent().hasClass('active')) {
            //$('.hero.active').height($('video').height())
            videoTag.play();
        }
    }

    videoHeighFix();


    $(window).on('load', function () {

        

        if ($('.hero').hasClass('active')) {
            //VideoCompletedTime();
            if (Foundation.MediaQuery.is('medium only') || Foundation.MediaQuery.is('small only')) {
                $('.hero').removeClass('active');
                //$('.hero').height('auto')
            }
        }

        if ($('.hero iframe').parent().hasClass('active')) {
            player.playVideo();
        }

    })

    //$(window).scroll(function () {
    //    var height = $(window).scrollTop();
    //    if ($('.hero').find('iframe,video').length > 0) {
    //        if ($('.hero').height() < height - 100) {
    //            $('.hero').removeClass('active');
    //            $('.hero').removeAttr('style');
    //            //var videoTag = $('#video')[0];
    //            videoTag.pause();
    //        } else {
    //            $('.hero').addClass('active');
    //            videoTag.play();
    //        }
    //    }
    //})

    $('.hero').on('click', '#stop-button', function () {
        document.getElementsByClassName("hero")[0].classList.remove("active");
        //player.stopVideo();
        player.pauseVideo();
    })

});