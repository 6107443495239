var contentStorefront = contentStorefront || {}

contentStorefront.masonry = function() {
    // functions needed in other JS files
    // add them to contentStorefront scope
    this.featuredMargin = featuredMargin


    setTimeout(function() {
        featuredMargin('.masonry-css:not(.hide) .masonry-css-item')
    }, 500)

    // check for image load to re-run script to adjust margins
    var images = $('.masonry-css-item img.new-card-image')
    images.each(function(i, image) {
        image.onload = function(e) {
            setTimeout(function() {
                featuredMargin('.masonry-css:not(.hide) .masonry-css-item')
            }, 250)
        };
    });

    function featuredMargin(items) {
        if(contentStorefront.current()!=='highlights')
            return
        
        items = $(items)
        if (!items.length)
            return;

        var margin = 40
        items.sort(function(a, b) {
            // orders items in columns to be able to calculate the negative margin
            return $(a).position().left - $(b).position().left || $(a).position.top - $(b).position().top;
        });
        var l = 0
        items.each(function(i, e) {
            if (l !== $(e).position().left)
                l = $(e).position().left

            if (i === 0) { // always 0 margin top for firrst item
                e.style.marginTop = 0
                return
            }
            // static height when in 'mobile' view
            if($(window).width() < 895){
                e.style.marginTop = '-20px'
                return
            }
            
            var prevItem = items[i - 1]
            if (prevItem.getBoundingClientRect().left !== e.getBoundingClientRect().left) {
                e.style.marginTop = 0
                return
            }
            // offset is the differance between the previous elements container height and the card height
            var offset = $(prevItem).find('.inner-new-card')[0].getBoundingClientRect().height - prevItem.getBoundingClientRect().height
            // add negative margin to items
            e.style.marginTop = offset + margin + 'px'
        });
    }
}