$(function () {

    //if (Foundation.MediaQuery.atLeast('large')) {

        var $mediaWrap = $('.media-without-icon');
        var $nav = $mediaWrap.find('.align-middle');
        var $btn = $mediaWrap.find('button');
        var $vlinks = $mediaWrap.find('.align-middle>ul');
        var $hlinks = $mediaWrap.find('.hidden-links>ul');

        if (!$mediaWrap.find('.small-order-2').next('.cell').is(":visible")) {
            $mediaWrap.find('.small-order-2').attr('class', 'cell')
        }

        var breaks = [];

        function updateNav() {

            var availableSpace = $btn.hasClass('hide') ? $nav.width() : $nav.width() - $btn.width() - 30;

            // The visible list is overflowing the nav
            if ($vlinks.width() >= availableSpace) {

                // Record the width of the list
                breaks.push($vlinks.width());

                // Move item to the hidden list
                $vlinks.children().last().prependTo($hlinks);

                // Show the dropdown btn
                if ($btn.hasClass('hide')) {
                    $btn.removeClass('hide');
                }

                // The visible list is not overflowing
            } else {

                // There is space for another item in the nav
                if (availableSpace >= breaks[breaks.length - 1]) {

                    // Move the item to the visible list
                    $hlinks.children().first().appendTo($vlinks);
                    breaks.pop();
                }

                // Hide the dropdown btn if hidden list is empty
                if (breaks.length < 1) {
                    $btn.addClass('hide');
                    $hlinks.addClass('hide');
                }
            }

            // Keep counter updated
            $btn.attr("count", breaks.length);

            // Recur if the visible list is still overflowing the nav
            if ($vlinks.width() > availableSpace) {
                updateNav();
            }

        }

        // Window listeners

        $(window).resize(function () {
            updateNav();
        });

        $btn.on('click', function () {
            $hlinks.toggleClass('hide');
        });

        updateNav();
    //}
})