var contentStorefront = contentStorefront || {}
contentStorefront.modalScripts = function() {
    // functions needed in other JS files
    // add them to contentStorefront scope
    return {
        init: init,
        shared: {
            closeModal: closeModal,
            openModal: openModal
        },
        // default volume settings
        volume: .25,
        muted: false
    };

    var modalHTML
    var ytTimeUpdator
    var openModal
    // functions needed in other JS files
    // add them to contentStorefront scope
    var getSectionHTML

    // get modal navigation buttons
    var nextEpisodeButton
    var prevEpisodeButton
    var nextSeriesButton

    // variable to see if tracking call has been sent
    var sentWatched = false
    // var updateModal 
    function closeModal(evt) {
        var modalHTML = modalHTML || $('.content-store-front.modal')
        if (!modalHTML || !modalHTML.hasClass('modal-opened'))
            return

        setTimeout(function() {
            // remove src from video and iframe
            modalHTML.find('.players').html('')
        }, 400)
        // hide modal by removing class
        modalHTML.removeClass('modal-opened');
        // remove class that stops scrolling of site
        $('body, html').removeClass('overflow-hidden');
        var section = (history.state && history.state.section ? history.state.section : 'video')
        history.replaceState(history.state, 'Content Storefront - Watch', '?storefront=' + section);

        // clear polling of YouTube videos when closing modal
        clearInterval(ytTimeUpdator)
    }

    // render Youtube Player iframe HTML
    function youtubePlayer(src) {
        var player = $('<iframe src="' + src + '"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>');
        return player
    }

    // render standard video player
    function videoPlayer(src) {
        var player = $('<video src="' + src + '" controls="true" volume="0">');
        return player
    }


    // get width of container by subtractinvg the elements above and below the video from the window height
    // set width of container to height / ratio for easy height with CSS to maintain video ratio
    function resizeVideo() {
        var ratio = 9 / 16
        var content = modalHTML.find('.content')
        var player = modalHTML.find('.players')
        // get height of area above video
        var topH = modalHTML.find('.modal-close').outerHeight(true)
        // get height of area below video
        var bottomH = modalHTML.find('.modal-info').outerHeight(true)
        var windowH = $(window).outerHeight(true)

        // get desired height of video
        var newH = windowH - topH - bottomH

        // dived by ratio to get width
        var newW = newH / ratio

        if (Foundation.MediaQuery.is('large')) {
            content.css({
                // add width to the sides to accound for the buttons
                'width': newW + 66,
                'maxWidth': 'calc(100% - 100px)'
            })
        } else {
            // remove inline style 
            content.css({
                'width': '',
                'maxWidth': ''
            })
        }

    }

    function openModal(id) {
        getSectionHTML = contentStorefront.getSectionHTML
        modalHTML = $('.content-store-front.modal')
        // get modal navigation buttons
        nextEpisodeButton = modalHTML.find('.next-episode')
        prevEpisodeButton = modalHTML.find('.prev-episode')
        nextSeriesButton = modalHTML.find('.next-series')

        // var $modalButton = this;
        sentWatched = false
        // uses getSectionHTML function to check if content exists
        // and if not makes ajax call to get it
        getSectionHTML('video')
            .then(function(data) {
                // console.log('getSectionHTML',data)
                // evt.preventDefault()
                // var content = $($modalButton).closest('.highlight-card-container')
                // var id = $modalButton.getAttribute('href')
                var vidContent = $(id)
                if(!vidContent.length)
                    return

                var seriesContent = vidContent.closest('.video-series-card-container')

                // location.hash = id



                // hide all links by default
                nextEpisodeButton.hide()
                prevEpisodeButton.hide()
                nextSeriesButton.hide()

                // show links and change attributes if they exist
                var nextVid = vidContent.next('.episode-card')
                if (nextVid.length) {
                    nextEpisodeButton
                        .attr('href', '#' + nextVid.attr('id'))
                        .show()
                } else {
                    var nextSeries = seriesContent.next()
                    if (nextSeries.length) {
                        var nextEpisode = nextSeries.find('.episode-card')[0]
                        nextSeriesButton
                            .attr('href', '#' + nextEpisode.id)
                            .show()
                    }

                }
                var prevVid = vidContent.prev('.episode-card')
                if (prevVid.length) {
                    prevEpisodeButton
                        .attr('href', '#' + prevVid.attr('id'))
                        .show()
                }

                // get display items from video

                // get series title
                var seriesTitle = seriesContent.find('.mobile-series-title').text()
                // get episode title
                var episodeTitle = vidContent.find('.episode-title').text()
                // mobile needs all content
                var mobileContent = vidContent.find('.text-container').html()
                // get video src
                var videoSrc = vidContent.data('video-src')
                // get goal id
                var videoGoalId = vidContent.data('video-goal')
                var seriesvideoid = seriesContent.find('.video-series-id').text()

                // data object to pass info to modal
                var data = {
                    seriesTitle: seriesTitle,
                    episodeTitle: episodeTitle,
                    videoSrc: videoSrc,
                    mobileContent: mobileContent,
                    videoGoalId: videoGoalId,
                    seriesvideoid: seriesvideoid
                }
                // update info
                updateModal(data)

                // display modal
                modalHTML.addClass('modal-opened')

                // stop scrolling of outside content
                $('body, html').addClass('overflow-hidden');
                // console.log(history.state.section)
                var section = (history.state && history.state.section ? history.state.section : 'video')
                history.replaceState(history.state, 'Content Storefront - Watch', '?storefront=' + section + '&episode=' + id.replace('#', ''));


                $(window).resize()

            })
    }


    // function to updatea modal data
    function updateModal(data) {

        // clear polling of YouTube videos when switching between modal windows
        clearInterval(ytTimeUpdator)
        // hide clear players if they exist
        modalHTML.find('.players').html('')
        // populate text fields
        modalHTML.find('.series-title').text(data.seriesTitle)
        modalHTML.find('.episode-title').text(data.episodeTitle)
        modalHTML.find('.video-series-id').text(data.seriesvideoid)
        // populate mobile fields
        // tankes content from ajax call to display video information
        modalHTML.find('.mobile-content').html(data.mobileContent)

        // checks if video src is from youtube and switches video type
        if (data.videoSrc.indexOf('youtube') > -1) {
            var player = youtubePlayer(data.videoSrc + '?autoplay=1&rel=0&modestbranding=1&enablejsapi=1')
            player.attr('id', "ytPlayer")
            modalHTML.find('.players').append(player)

            // Check if YouTube API is loaded
            if (typeof window.YT !== "undefined") {
                var ytPlayer;
                window.onYouTubeIframeAPIReady = function() {
                    // console.log('testing2')
                }
                ytPlayer = new YT.Player('ytPlayer', {
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange
                    }
                });
                var videotime = 0

                function updateTime() {
                    if (ytPlayer && ytPlayer.getCurrentTime) {
                        videotime = ytPlayer.getCurrentTime();
                    }
                    if (!sentWatched && videotime > 5) {
                        sentWatched = true
                        sendWatched(data.videoGoalId)
                        // clear polling of YouTube videos when closing modal
                        clearInterval(ytTimeUpdator)
                    }
                }

                if (data.videoGoalId) {
                    ytTimeUpdator = setInterval(updateTime, 100);
                }

                function onPlayerReady(event) {  
                    ytPlayer.playVideo()
                    // console.log('Player Ready', event)
                }

                // Video pause on click enjoy Link
                $('.enjoySignupLinkBrowser, .enjoySignupLinkMobile').on('click', function () {
                    ytPlayer.pauseVideo();
                });
               // Video play  after form close
                $('#videoSignUpModal').on('closed.zf.reveal', function () {
                    ytPlayer.playVideo();
                });

                function onPlayerStateChange(event) {
                    // console.log(onPlayerStateChange, event)
                }

            } else {
                // If no YouTube api then send call using setTimeout
                setTimeout(function() {
                    sendWatched(data.videoGoalId)
                }, 5000)
            }


        } else {
            var player = videoPlayer(data.videoSrc)
            modalHTML.find('.players').append(player)

            vid = player[0]

            // get volume from Var to keep video volume same when changing videos
            vid.volume = $this.volume
            vid.muted = $this.muted

            vid.addEventListener('loadeddata', function(evt) {
                if (vid.readyState >= 2) {
                    vid.play();
                }

            });

            // Set event listener to send trakcing after 5 seconds
            if (data.videoGoalId) {
                $(vid).on('timeupdate', function(e, stat) {
                    if (!sentWatched && vid.currentTime > 5) {
                        sentWatched = true
                        sendWatched(data.videoGoalId)
                        $(vid).off('timeupdate')
                    }
                });
            }
            // set volume from Var to keep video volume same when changing videos
            vid.addEventListener('volumechange', function(evt) {
                // set volume to stay when moving between videos
                $this.muted = this.muted
                $this.volume = this.volume

            });
            vid.load()
        }
        resizeVideo()
    } // updateModal()


    // function for sending video watched tracking call
    function sendWatched(goalID) {
        $.get("/api/sitecore/video/RegisterGoal?goalid=" + goalID, function(data) {
            console.log("Goal has been triggered");
        });
    }

    function init() {
        var $this = this;
        modalHTML = $('.content-store-front.modal')
        // functions needed in other JS files
        // add them to contentStorefront scope
        getSectionHTML = contentStorefront.getSectionHTML

        // get modal navigation buttons
        nextEpisodeButton = modalHTML.find('.next-episode')
        prevEpisodeButton = modalHTML.find('.prev-episode')
        nextSeriesButton = modalHTML.find('.next-series')

        // modal open event listener
        $('.modal-open')
            .off('click')
            .on('click', function(evt) {
                evt.preventDefault()
                openModal($(this).attr('href'))
            }) //$('.modal-open').click()

        // modal close event listener
        $('.content-store-front .modal-close')
            .off('click')
            .on('click', function(evt) {
                evt.preventDefault();
                closeModal()
            })




        $(window).resize(resizeVideo)
        // setTimeout(function(){
        //     openModal('#42823776EE3E4B5BA35141C5793F84A3')
        // },2000)
    } // init()

}