var contentStorefront = contentStorefront || {}
contentStorefront.seriesScripts = function() {
    // functions needed in other JS files
    // add them to contentStorefront scope
    return {
        init: init
    };

    function init() {

        var speakers = $('.expanded-speaker')
        $('.open-speakers').on('click', function() {
            $('.speakers-open').removeClass('speakers-open')
            $(this).closest('.multiple-speakers').addClass('speakers-open')
            if (Foundation.MediaQuery.is('small only')) {
                $('body, html').addClass('overflow-hidden')
            }

        })
        $('.close-speakers').on('click', function() {
            $(this).closest('.multiple-speakers').removeClass('speakers-open')
            $('body, html').removeClass('overflow-hidden').removeClass('speakers-open')
        })

        // Button to open accordian is outside of accordion so needs to be triggered
        $('.series-learn-more')
            .off('click')
            .on('click', function(evt) {
                // alert('click')
                evt.preventDefault()
                var container = $(this).closest('.video-series-card-container')
                var trigger = container.find('.accordian-trigger')
                trigger.trigger('click')
                updateTriggerLink(this)
                checkBreakpoint()

            })

        $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
            // newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
            checkBreakpoint()
        });

        // update link copy depending on state of accordion button
        var updateTriggerLink = function(el) {
            var h5 = $('h5', el)
            var trigger = $(el).closest('.video-series-card-container').find('.accordian-trigger')
            // get value from trigger element for display
            $(el).attr('aria-expanded', trigger.attr('aria-expanded'))

            if ($(el).attr('aria-expanded') === 'true') {
                h5.text(h5.attr('data-copy-opened'))
                contentStorefront.moveSection($(el).parent())
            } else
                h5.text(h5.attr('data-copy-closed'))
        }

        var addSlide = function(slider) {
            if (slider) {
                var slide = '<div class="spacer-slide"/>'
                $(slider).slick('slickAdd', slide);
            }
        }
        var removeSlide = function(slider, slide) {
            $(slider).slick('slickRemove', slide - 1);
        }
        // slider settings for when there are upcoming episodes in series
        $('.series-container.isUpcoming').slick({
            infinite: false,
            speed: 300,
            prevArrow: '<button class="slick-prev slick-button" aria-label="Previous" type="button" style="" aria-disabled="false">Previous</button>',
            nextArrow: '<button class="slick-next slick-button" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
            slidesToShow: 3,
            slidesToScroll: 3,
            slideOffset: 2,
            responsive: [{
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        })

        // slider settings for when there are not upcoming episodes in series
        $('.series-container:not(".isUpcoming")').slick({
            infinite: false,
            speed: 300,
            prevArrow: '<button class="slick-prev slick-button" aria-label="Previous" type="button" style="" aria-disabled="false">Previous</button>',
            nextArrow: '<button class="slick-next slick-button" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
            slidesToShow: 4,
            slidesToScroll: 4,
            slideOffset: 2,
            responsive: [{
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }]
        })

        // function to 'slick' and 'unslick' slider depending on breakpoints
        function checkBreakpoint() {
            // don't make changes if not in video section
            if (contentStorefront.current() !== 'video')
                return

            if (Foundation.MediaQuery.is('small only')) {
                $('.series-container').slick("unslick");
            } else {
                $('.series-container').slick("refresh");
                designSlides($('.series-container'))
            }


            // remove speaker modals when changing breakpoints
            $('.speakers-open').removeClass('speakers-open')
            // don't remove overflow hidden if video model is open
            if (!$('.content-store-front.modal').hasClass('modal-opened'))
                $('body, html').removeClass('overflow-hidden')



        }

        function designSlides(slides) {
            // checks real slides vs added slides
            // finds optimal # of slided to display as UX intended
            slides.each(function(i, e) {
                var slick = $(this).slick('getSlick')
                var total = slick.slideCount;
                var origSlides = $('.episode-card', this).length
                var visible = slick.options.slidesToShow
                var slides = Math.ceil(origSlides / visible);
                var optimalTotal = slides * visible;
                var offset = optimalTotal - total
                if (offset > 0) {
                    for (var step = 0; step < offset; step++) {
                        addSlide(this)
                    }
                } else if (offset < 0) {
                    for (var step = 0; step < Math.abs(offset); step++) {
                        removeSlide(this, total)
                    }
                }
            })
        }


        $('.mobile-navigation a')
            .off('click')
            .on('click', function(evt) {
                evt.preventDefault()
                var clicked = $(this).text()
                var item = $(this).attr('href')

                $(this).closest('.storefront-tabs').find('.storefront-tab, .mobile-navigation a').removeClass('isActive')
                $(item).addClass('isActive')
                $(this).addClass('isActive')

                if (clicked === 'Overview') {
                    $(".mobile-navigation").stop().animate({ scrollLeft: '100' }, 1000);
                } else if (clicked === 'Watch Now') {
                    $(".mobile-navigation").stop().animate({ scrollLeft: '-100' }, 1000);
                }
            })


        //  iterate through accorion buttons to see if any are open
        // and then change element accordingly
        $('.series-learn-more').each(function(i, e) {
            updateTriggerLink(e)
        })
        $('.series-container').each(function(index) {
            var el = $(this).closest('.accordion')
            new Foundation.Accordion(el)
            var hasUpcoming = $(this).hasClass('isUpcoming')
            if (!hasUpcoming) {
                $(this).find('.image-container').css('padding-bottom', '.5rem')
                $(this).find('.image-container').css('padding-left', '.5rem')
                $(this).find('.image-container').css('padding-right', '.5rem')
                $(this).find('.text-container').css('padding', '.5rem')
            } else {
                $(this).find('.image-container').css('padding-top', '0rem')
            }
        })

        // Vodeo Sign Up Form
        $(document).ready(function () {
            $(".emailInput").val("");          
        });
        var emailSignUpformValidation = function () {
            //var form = $(this).closest(".emailSignUp");

            $(".field-validation-error").html("").removeClass("field-validation-error");
            var noError = true;

            if ($(".emailSignUpVideo").find(".emailInput").val() === "") {
                noError = false;
               // $(".emailSignUpVideo").find(".emailInput").next(".field-validation-valid").addClass("field-validation-error").html("<span>*</span>");
                $(".emailSignUpVideo .emailSignupInput").find("label").prepend("<span class='email-empty'>*</span>");
            }
            if ($(".emailSignUpVideo").find(".emailInput").val() !== "") {
                $(".emailSignUpVideo .emailSignupInput").find(".email-empty").remove();
                var patternEmail = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,17}$');
                //var regEmailX = new RegExp($(".emailSignUpVideo").find(".emailInput").attr("data-val-regex-pattern"));
                var EmailBoxValue = $.trim($(".emailSignUpVideo").find(".emailInput").val());

                if (!patternEmail.test(EmailBoxValue)) {
                    noError = false;
                    $(".emailSignUpVideo").find(".emailInput").next("span").addClass("field-validation-error email-invalid").html($("#hdnfrmemailval").val());

                } else {
                    if ($('.emailSignUpVideo .emailCheckboxes .emailCheckboxesList input:checked').length > 0) {
                           var emailSignUpVideoList = [];
                        $('.emailSignUpVideo .emailCheckboxes .emailCheckboxesList input:checked').each(function () {
                            emailSignUpVideoList.push($(this).parent().text());
                           });
                            var Videolist = '<ul class="video-email-checklist"><li class="video-list" role="menuitem">' + emailSignUpVideoList.join('</li><li class="video-list">') + '</li></ul>';
                            $('.updateListItems').html(Videolist);
                    
                        }
                       $(".emailSignUpVideo").find(".emailsection").addClass("hide");
                      $(".emailSignUpVideo").find(".recaptchasection").removeClass("hide");
                }   
            }
        };
        $('.emailSignUpVideo .emailInput').on('keydown', function (e) {
            $(".emailSignUpVideo").find(".emailInput").next("span").removeClass("field-validation-error email-invalid");
            $(".emailSignUpVideo").find(".emailInput").next("span").empty();
        });

        $('#videoSignUpModal').on('closed.zf.reveal', function () {
            $('.emailSignUpVideo').find('.previousButton').trigger('click');
            $(".updateListItems").empty();
        });
        $('#videoSignUpModal').on('closeme.zf.reveal', function () {
            $('.emailSignUpVideo .emailInput').val('');
            $(".emailSignUpVideo .emailSignupInput").find(".email-empty").remove();
            $(".emailSignUpVideo").find(".emailInput").next("span").removeClass("field-validation-error email-invalid");
            $(".emailSignUpVideo").find(".emailInput").next("span").empty();
            $(".emailSignUpVideo").find(".emailsection").removeClass("hide");
            $(".emailSignUpVideo").find(".recaptchasection").addClass("hide");
            ////$('.emailSignUpVideo .emailCheckboxes input:checkbox').each(function () {
            ////    $(this).prop('checked', false);
            ////});

            //var emailmatchedList = [];
            var videoseriesid = $('.content-store-front.modal').find('.video-series-id').text();
            $('.emailSignUpVideo .emailCheckboxes input[type=checkbox]').each(function () {
                if ($(this).val() == videoseriesid) {
                    $(this).prop('checked', true);
                }
                else {
                    $(this).prop('checked', false);
                }
            });
            Videolist = null;
            emailSignUpVideoList = [];
            $(".updateListItems").empty();
            $(".emailSignUpVideo").find(".signupButton").click(function () { emailSignUpformValidation(); });
            $(".emailSignUpVideo").find(".signupButton").keypress(function (event) {
                if (event.which == 13) {
                    emailSignUpformValidation();
                }
            });
            $(".emailSignUpVideo").find(".emailInput").keypress(function (event) {
                if (event.which == 13) {
                    event.preventDefault();
                    emailSignUpformValidation();
                    return false;
                }
            });
            $(".emailSignUpVideo").keypress(function (event) {
                if (event.which == 13) {
                    event.preventDefault();
                    return false;
                }
            });
            $(".emailSignUpVideo .emailCheckboxes").find(".selectAllOpt").click(function () {
                if (this.checked) {
                    $(".emailCheckboxes input[type=checkbox]").prop("checked", true);
                } else {
                    $(".emailCheckboxes input[type=checkbox]").prop("checked", false);
                }	
               // $(".emailCheckboxes input[type=checkbox]").prop('checked', $(this).prop('checked'));
            });
            $(".emailSignUpVideo").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]").click(function () {
                var numberOfCheckboxes = $(".emailSignUpVideo").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]").length;
                var numberOfCheckboxesChecked = $(".emailSignUpVideo").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]:checked").length;
                if (numberOfCheckboxes == numberOfCheckboxesChecked) {
                    $(".selectAllOpt").prop("checked", true);
                } else {
                    $(".selectAllOpt").prop("checked", false);
                }
            });
        });


    }


}

// }