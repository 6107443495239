var contentStorefront = contentStorefront || {}
$(function() {

    var isStorefront = $('.content-storefront').length ? true : false
    if (!isStorefront)
        return;
    // If Loader HTML does not exist then write it to hte page
    if (!$('.loader').length) {
        $('body').append('<div class="loader reveal-overlay" style="display: none;"><div><img src="/images/loader.gif"> Loading...</div></div>')
    }

    // YouTube iFrame API scripts
    var tag = document.createElement('script');
    tag.id = 'storefront-iframe-api';
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // end youtube embed scripts


    // set section vairables here
    var storefrontNav = $('.storefront-nav')
    // set up shared JS functions
    contentStorefront.getSectionHTML = getSectionHTML


    contentStorefront.current = function() {
        return sectionloaded.current
    }

    contentStorefront.moveSection = moveSection

    // variable to manage script initiation
    var sectionloaded = {
        highlights: false,
        video: false,
        podcast: false,
        current: 'highlights'
    }

    // functions for highlights page
    function hightlightsFunctions() {
        // switch to podcast tab and initiate playback
        $('.podcast-open').on('click', function(evt) {
            evt.preventDefault()
            var container = $(this).closest('.highlight-card-container')
            var podcastID = container.attr('data-content-id');
            var url = '?storefront=podcast&podcast=' + podcastID //#' + podcastID
            var section = 'podcast'
            updateSection(section)
                .then(function(data) {
                    history.pushState({ 'last_top': $(window).scrollTop(), 'section': section }, 'Content Storefront - ' + section, url);
                    moveSection($('#' + podcastID))
                    contentStorefront.podcastScripts().shared.playEpisode(podcastID)
                })
        })

        // highlights page Form
        var selectedList = [];
        var list = null;
        $(document).ready(function () {

            $(".emailInput").val("");
            selectedList = [];
            list = null;
            $(".highlightUpdateListItems").empty();
        });
        //press enter on email call eamil validation function
        $(".emailSignUpHighlights").find(".emailInput").keypress(function (event) {
            if (event.which == 13) {
                event.preventDefault();
                emailSignUpformValidation();
                 return false;
            }
        });
        $(".emailSignUpHighlights").keypress(function (event) {
            if (event.which == 13) {
                event.preventDefault();
                return false;
            }
        });
        $(".emailSignUpHighlights").find(".signupButton").keypress(function (event) {
            if (event.which == 13) {
                emailSignUpformValidation();
            }
        });
        $(".emailSignUpHighlights").find(".signupButton").click(function () { emailSignUpformValidation(); });

        $(".emailSignUpHighlights .emailCheckboxes").find(".selectAllOpt").click(function () {  
            if (this.checked) {
                $(".emailCheckboxes input[type=checkbox]").prop("checked", true);
            } else {
                $(".emailCheckboxes input[type=checkbox]").prop("checked", false);
            }	
        });
        $(".emailSignUpHighlights").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]").click(function () {
            var numberOfCheckboxes = $(".emailSignUpHighlights").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]").length;
            var numberOfCheckboxesChecked = $(".emailSignUpHighlights").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]:checked").length;
            if (numberOfCheckboxes == numberOfCheckboxesChecked) {
                $(".selectAllOpt").prop("checked", true);
            } else {
                $(".selectAllOpt").prop("checked", false);
            }
        });
        //*****email validation Function***
        var emailSignUpformValidation = function () {

            //var form = $(this).closest(".emailSignUp");
            $(window).resize();
            $(".field-validation-error").html("").removeClass("field-validation-error");
            var noError = true;

            if ($(".emailSignUpHighlights").find(".emailInput").val() === "") {
                noError = false;
                $(".emailSignUpHighlights .emailSignupInput").find("label").prepend("<span class='email-empty'>*</span>");
            }
            if ($(".emailSignUpHighlights").find(".emailInput").val() !== "") {
                $(".emailSignUpHighlights .emailSignupInput").find(".email-empty").remove();
                var patternEmail = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,17}$');
                //var regEmailX = new RegExp($(".emailSignUpHighlights").find(".emailInput").attr("data-val-regex-pattern"));
                var EmailBoxValue = $.trim($(".emailSignUpHighlights").find(".emailInput").val());

                if (!patternEmail.test(EmailBoxValue)) {
                    noError = false;
                    $(".emailSignUpHighlights").find(".emailInput").next("span").addClass("field-validation-error email-invalid").html($("#hdnfrmemailval").val());

                } else {
                    if ($('.emailSignUpHighlights .emailCheckboxes .emailCheckboxesList input:checked').length > 0) {

                        $('.emailSignUpHighlights .emailCheckboxes .emailCheckboxesList input:checked').each(function () {
                            selectedList.push($(this).parent().text());
                        });
                       // var listIcon = '<svg class="edgeSvg" style="fill:url(#grad1);vertical-align:middle" width="16px" height="16px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">< defs ><linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style="stop-color:#2D66FF;stop-opacity:1" /><stop offset="100%" style="stop-color:#00E1DC;stop-opacity:1" /></linearGradient></defs > <polygon id="path-1" points="34.453611 5.74992 51.74928 5.74992 28.7965627 57.4992 11.49984 57.4992"></polygon></svg >';
                        list = '<ul class="highlights-email-checklist"><li class="highlights-list" role="menuitem">' + selectedList.join('</li><li class="highlights-list">') + '</li></ul>';
                        $('.highlightUpdateListItems').html(list);

                    }
                    $(".emailSignUpHighlights").find(".emailsection").addClass("hide");
                    $(".emailSignUpHighlights").find(".recaptchasection").removeClass("hide");
                }
            }


        };
        //on Key down hiding email error message
        $('.emailSignUpHighlights .emailInput').on('keydown', function (e) {
            $(".emailSignUpHighlights").find(".emailInput").next("span").removeClass("field-validation-error email-invalid");
            $(".emailSignUpHighlights").find(".emailInput").next("span").empty();
        });
    }

    var deepLink = deepLink()

    // run updateSection function by default whan loading pages
    updateSection(getSection())
        .then(function() {
            // check for deep linking options
            if (deepLink) {
                var id = "#" + deepLink.id
                if (deepLink.section === 'episode' && (getSection() === 'video' || getSection() === 'highlights')) {
                    setTimeout(function() {
                        contentStorefront.modalScripts().shared.openModal(id)
                        if(getSection()!=='video')
                            return
                        var seriesID = 'series-' + deepLink.id
                        var trigger = $(id).closest('.video-series-card-container').find('.series-learn-more')
                        setTimeout(function() {
                            trigger.trigger('click')
                        }, 550)
                    }, 500)

                } else if (deepLink.section === 'podcast') {
                    if($(id).length)
                        moveSection($(id))
                } else if (deepLink.section === 'series') {
                    var series = $('#' + deepLink.id)
                    var seriesID = 'series-' + deepLink.id
                    var trigger = series.find('.series-learn-more')
                    // setTimeout(function() {
                        trigger.trigger('click')
                    // }, 250)
                    // }
                }
            }
        })


    // highlights resize events
    $(window).resize(function() {
        if (sectionloaded.highlights && sectionloaded.current === 'highlights') {
            contentStorefront.featuredMargin('.masonry-css:not(.hide) .masonry-css-item')
        }
    }).resize()


    // Storefront Tab Navigation
    $('.storefront-nav a').on('click', function(evt) {
        evt.preventDefault()

        // don't change section if on active tab
        if ($(this).parent().hasClass('active') || $(this).parent().hasClass('is-active')) {
            return
        }
        var section = $(this).attr('data-storefront-tab')
        history.pushState({ 'last_top': $(window).scrollTop(), 'section': section }, 'Content Storefront - ' + section, this.href);
        updateSection(section)
    })


    // Update Tabs
    function updateSection(section) {
        // getSectionHTML checks if content is already loaded
        // if not makes ajax call to get section html

        return getSectionHTML(section)
            .then(function(data) {


                // updates storefront nav display
                $('.storefront-nav li').removeClass('active')
                $('.storefront-nav li.' + section).addClass('active')

                // run function to change svg to be inline
                img2svg()
                // return;
                // sets loaded section for surpressing events if needed
                sectionloaded.current = section

                // conditionally load scripts as sections are added to page
                // only load scripts once to prevent bubbling up of events
                if (!sectionloaded.highlights && section === 'highlights') {
                    contentStorefront.modalScripts().init()
                    hightlightsFunctions()
                    contentStorefront.masonry()
                    sectionloaded.highlights = true
                }
                if (!sectionloaded.video && section === 'video') {
                    contentStorefront.modalScripts().init()
                    contentStorefront.seriesScripts().init()
                    sectionloaded.video = true
                }
                if (!sectionloaded.podcast && section === 'podcast') {
                    contentStorefront.podcastScripts().init()
                    sectionloaded.podcast = true
                }
                // pauses podcasts if switching from that tab
                if (section !== 'podcast')
                    contentStorefront.podcastScripts().shared.pauseAll()

                // updates storefront content that is displayed
                $('.storefront-section').removeClass('hide')
                $('.storefront-section:not(".' + 'storefront-' + section + '")').addClass('hide')

                // mobile dropdown needs to change display of active item

                var sectionTitle = $('.storefront-nav-container a[data-storefront-tab=' + section + ']').text()
                // console.log(sectionTitle.text())
                storefrontNav.find('.menu-label span').text(sectionTitle)
                storefrontNav.find('[role="menuitem"]').attr('aria-label', section)

                // defauult close video modal if open with switching sections
                contentStorefront.modalScripts().shared.closeModal()


                // trigger window resize to adjust any dynamic content after html has been loaded
                $(window).resize().trigger('changed.zf.mediaquery')
                setTimeout(function() {
                    $(window).resize().trigger('changed.zf.mediaquery')
                }, 250)
            })
    }


    // anchor to ID or top if no ID
    function moveSection(el) {
        var pos = 0

        // var item = $('#' + id)
        pos = el.offset().top - 100

        $("html, body").animate({ scrollTop:pos});
        // $(window).scrollTop(pos)
    }

    // getSectionHTML checks if content is already loaded
    // if not makes ajax call to get section html
    function getSectionHTML(section) {
        var sectionHTML = $('#storefront-' + section)
        var hasHTML = sectionHTML.length
        // if HTML already exists then do not make ajax call
        if (!!hasHTML) {
            var defer = $.Deferred();
            return defer.resolve(true)
        } else {
            // Show Loader 
            $('.loader').show();
            // if HTML does not exist then make ajax call

            /****************************/
            // This will likely have to be changed when added to CMS
            /****************************/
            var url = section + '.html'
            /****************************/
            if (section === "video") {
                url = "/api/sitecore/Outfrontx/GetVideos";
            }
            else if (section === "podcast") {
                url = "/api/sitecore/Outfrontx/GetPodcasts";
            }
            else {
                url = "/api/sitecore/Outfrontx/GetHighlights";
            }


            return $.get(url, function(data) {
                var html = $(data).addClass('hide')
                $('section.content-storefront').append(html)
                setTimeout(function() {
                    // Hide Loader 
                    $('.loader').hide();
                }, 750)
            })
            .catch(function(err){
                $('.storefront-nav a[data-storefront-tab="highlights"]').trigger('click')
            })
        }
    }

    // Use JS History API to change the content when using browser navigation buttons
    window.addEventListener('popstate', function(event) {
        updateSection(event.state.section)
    });


    // utility functions to get the currect section
    function getSection() {
        var queryStringValues = getUrlVars();

        if (queryStringValues.q !== undefined) {
            queryStringValues = queryStringValues.q.split('%20').join(' ');
            queryStringValues = queryStringValues.split('+').join(' ');
        }

        var section = 'highlights'
        if (queryStringValues && queryStringValues.storefront) {
            section = queryStringValues.storefront
        } else {
            // defaults to highlights section
            history.replaceState({ 'last_top': $(window).scrollTop(), 'section': section }, 'Content Storefront - ' + section, '?storefront=highlights');
        }
        return section
    }

    function deepLink() {
        var queryStringValues = getUrlVars();

        if (queryStringValues.q !== undefined) {
            queryStringValues = queryStringValues.q.split('%20').join(' ');
            queryStringValues = queryStringValues.split('+').join(' ');
        }
        var deepLink = false
        if (queryStringValues && queryStringValues.episode) {
            deepLink = {
                section: 'episode',
                id: queryStringValues.episode
            }
        }
        if (queryStringValues && queryStringValues.podcast) {
            deepLink = {
                section: 'podcast',
                id: queryStringValues.podcast
            }
        }
        if (queryStringValues && queryStringValues.series) {
            deepLink = {
                section: 'series',
                id: queryStringValues.series
            }
        }
        return deepLink

    }

    // utility functions to get the currect url Vars
    function getUrlVars() {
        var string = window.location.search
        var vars = [],
            hash;
        var hashes = string.slice(string.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    // create this as a function that can be called when dynamic content is loaded
    function img2svg() {
        /*Replace all SVG images with inline SVG*/
        $('img.img2svg').each(function() {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
    }

});





