$(function () {
    if (Foundation.MediaQuery.atLeast('large')) {
        $('.tiles .front-tiles').each(function () {
            var getNextTiles = $(this).parent().find('.back-tiles');
            $(this).hover(function () {
                $(this).addClass('active');

                setTimeout(function () {
                    getNextTiles.addClass('active');
                }, 500)

            }, function () {
                $(this).removeClass('active');

                setTimeout(function () {
                    getNextTiles.removeClass('active');
                }, 500)
            });
        });

        $('.tiles .front-tiles>.cell').hover(
            function () {
                var getTileName = $(this).attr('data-tile');
                //$(this).parent().parent().find('[data-id="' + getTileName + '"] .bg').fadeOut();
                $(this).parent().parent().find('[data-id="' + getTileName + '"] video')[0].play();


                $(this).children('ul').stop(true, true).animate({
                    top: 0,
                    opacity: 1
                }, 500)

                $(this).parent().parent().find('[data-id]').stop(true, true).animate({
                    width: '0%'
                })
                $(this).parent().parent().find('[data-id="' + getTileName + '"]').addClass('active').stop(true, true).animate({
                    width: '100%',
                    opacity: '1'
                },
                    function () {
                        $(this).parent().parent().find('[data-id="' + getTileName + '"]').addClass('active');
                    });

                //console.log(getTileName);
            },
            function () {
                var getTileName = $(this).attr('data-tile');

                $(this).parent().parent().find('[data-id="' + getTileName + '"] .bg').fadeIn();
                $(this).parent().parent().find('[data-id="' + getTileName + '"] video')[0].pause();
                $(this).children('ul').stop(true, true).animate({
                    opacity: 0,
                    top: 0
                }).animate({
                    top: 10
                })

                $(this).parent().parent().find('[data-id]').removeAttr('style');
                $(this).parent().parent().find('[data-id="' + getTileName + '"]').removeClass('active').stop(true, true).animate({
                    width: '0%',
                    opacity: '0'
                },
                    function () {
                        $(this).parent().parent().find('[data-id]').removeAttr('style');
                        $(this).parent().parent().find('[data-id="' + getTileName + '"]').removeClass('active');
                    }
                );


            }
        );
    }

    $('.tiles>.back-tiles>div')
    $('.tiles').each(function () {
        var getFtList = $(this).children('.front-tiles').children('div');
        //console.log(getFtList.length)
        getFtList.parent().parent().find('.front-tiles, .back-tiles').removeClass('large-up-4').addClass('large-up-' + getFtList.length)
        /*if (getFtList.length >= 4) {
            //getFtList.eq(2).nextAll().addClass('test').wrapAll('<section class="tiles"><div class="grid-x grid-padding-3 large-up-4 medium-up-1 small-up-1 front-tiles"></div></section>')

            getFtList.parent().parent().find('.front-tiles, .back-tiles').removeClass('large-up-4').addClass('large-up-3')
            console.log('true')
        } else {
            console.log('false')
        }*/
    });


})