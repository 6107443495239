var contentStorefront = contentStorefront || {}
var activePodcastEpisode;
var emailSignUpPodcastList = [];
var Podcastlist = null;
contentStorefront.podcastScripts = function() {
    // state for selected episode
    // functions needed in other JS files
    // add them to contentStorefront scope
    return {
        init: init,
        shared: {
            pauseAll: pauseAll,
            playEpisode: playEpisode
        },
    };
    var sentListened = false

    function pauseAll(audioPlayer, episode) {
        $('audio').each(function(i, e) {
            $('.podEpisodeContainer').removeClass('playing')
            this.pause()
        })
        $('.podcastSignupLinkBrowser').addClass('hide');
        $('.podcastSignupLinKMobile').addClass('hide');
        $('.podcastSignupLinKLandscape').addClass('hide');

    }

    function playEpisode(id) {
        //
        // remove class ended swaps the replay button for pause
        var container = $('#' + id)
        audioPlayer = container.find('audio')[0]

        if (audioPlayer.readyState < 4) {
            // show loader if player is not ready
            $('.loader').show();
        }

        audioPlayer.play();
        if (audioPlayer.currentTime <= 5) {
            sentListened = false;
        }
        else {
            sentListened = true;
        }
        if (container.hasClass('replay')) {
            container.removeClass('replay')
            audioPlayer.currentTime = 0
        }
        container.addClass('playing')
        $('.podEpisodeContainer.selected').removeClass('selected')
        container.addClass('selected')
        hideAndShowFormink();

    }
    function hideAndShowFormink() {
        var windowsize = $(window).width();
            //$('.selected .podcastSignupLinkBrowser').removeClass('hide');
        if (windowsize < 900 && window.matchMedia("(orientation: landscape)").matches) {
            console.log('sasa')
            $('.selected .podcastSignupLinKLandscape').removeClass('hide');
            return;
        }

            if (windowsize > 768) {
                $('.selected .podcastSignupLinKMobile').addClass('hide');
                $('.selected .podcastSignupLinkBrowser').removeClass('hide');
                $('.selected .podcastSignupLinKLandscape').addClass('hide');
            }
            else {
                $('.selected .podcastSignupLinKMobile').removeClass('hide');
                $('.selected .podcastSignupLinkBrowser').addClass('hide');
                $('.selected .podcastSignupLinKLandscape').addClass('hide');
            }

    }

    function sendListened(goalID) {
       // console.log("test podcast");
        $.get("/api/sitecore/video/RegisterGoal?goalid="+ goalID, function (data) {
            console.log("Podcast Goal has been triggered");
        });
    }
    function init() {
        // state for scrubber click
        var scrubberClick = false
        // get all podcast players
        var podcastPlayers = $('#storefront-podcast audio')
       // sentListened = false
        //Bind events to players
        // Sets scrubber values and changes max/min values
        podcastPlayers.each(function() {
            var audioPlayer = this
            if (audioPlayer.readyState < 4) {
                audioPlayer.onloadedmetadata = function() {
                    elapsedTime(audioPlayer)
                    loadScrubber(audioPlayer)
                }
            } else {
                elapsedTime(audioPlayer)
                loadScrubber(audioPlayer)
            }
            audioPlayer.onended = function(event) {
                podcastOver(audioPlayer)
            }
        })

        // click event to play/pause episode
        $(".playPause")
            .off('click')
            .on('click', function(evt) {

                //check to see if restart needs to be swaped with play button 
                var playButton = $(this).find('.podcast-play-button')
                evt.preventDefault()
                playPause(this)

            })

        function podcastOver(audioPlayer) {
            var container = $(audioPlayer).closest('.podEpisodeContainer')
            var playButton = $(container).find('.podcast-play-button')[0]
            container.removeClass('playing').addClass('replay')
        }

        function playPause(audio) {
            var container = $(audio).closest(".podEpisodeContainer")
            var audioPlayer = (container).find('.audio-player');

            loadScrubber(audioPlayer[0])
            if (container.hasClass('playing')) {
                pauseAll()
                
            } else {
                pauseAll()
                playEpisode(container.attr('id'))
            }
            activePodcastEpisode = container.attr('id');
  
       
        }


        // this should grab the selected audioPlayer's range inputs and pass them to updateScrubbers function
        function grabScrubbers(scrubbers, currentTime) {
            var currTime = Math.floor(currentTime)
            for (var i = 0; i < scrubbers.length; i++) {
                updateScrubber(scrubbers[i], currTime);
            }
        };

        // maps background on range input to elapsed time
        function updateScrubber(scrubber, currentTime) {
            scrubber.value = currentTime

            var val = ($(scrubber).val() - $(scrubber).attr('min')) / ($(scrubber).attr('max') - $(scrubber).attr('min'));
            var percent = val * 100;

            $(scrubber).css('background-image',
                '-webkit-gradient(linear, left top, right top, ' +
                'color-stop(' + percent + '%, #404040), ' +
                'color-stop(' + percent + '%, #CCCCCC)' +
                ')');

            $(scrubber).css('background-image',
                '-moz-linear-gradient(left center, #DF7164 0%, #DF7164 ' + percent + '%, #F5D0CC ' + percent + '%, #F5D0CC 100%)');
            // }
        }

        // totalTime for current track
        function getTotalTime(audioPlayer) {
            // total minutes
            var totalMinutes = Math.floor(audioPlayer.duration / 60)

            // total seconds
            var totalSeconds = Math.floor(audioPlayer.duration % 60)
            totalSeconds = totalSeconds > 9 ? totalSeconds : '0' + totalSeconds;
            
            // total time 
            var totalTime = totalMinutes + ':' + totalSeconds
            return totalTime
        }

        // formats and updates time for selected episode
        function elapsedTime(audioPlayer) {

            var totalTime = getTotalTime(audioPlayer)

            audioPlayer.addEventListener("timeupdate", function() {
                if (totalTime.indexOf('NaN') === 0) {
                    totalTime = getTotalTime(audioPlayer)
                }

                // hide loader
                $('.loader').hide();
                
                var timeText = $(audioPlayer).parent('.podEpisodeContainer').find('.time-text')
                var currentTime = audioPlayer.currentTime
                var scrubbers = $(audioPlayer).parent('.podEpisodeContainer').find('.slider')
                var elapsedMinutes = currentTime / 60
                var elapsedSeconds = currentTime % 60
                var secondsLength = Math.floor(elapsedSeconds).toString().split('').length
                var formattedTime
                if (secondsLength < 2) {
                    formattedTime = Math.floor(elapsedMinutes) + ':' + '0' + Math.floor(elapsedSeconds) + ' / ' + totalTime
                } else {
                    formattedTime = Math.floor(elapsedMinutes) + ':' + Math.floor(elapsedSeconds) + ' / ' + totalTime
                }
                // update time
                timeText.html("").html(formattedTime);

                // update mobile and desktop scrubber 
                if (!scrubberClick) grabScrubbers(scrubbers, currentTime)
                var podcastGoalId = $(audioPlayer).parent('.podEpisodeContainer').data('podcat-goal');
                if (!sentListened && elapsedSeconds > 5) {
                    //console.log("test podcast" + podcastGoalId);
                    sentListened = true
                    sendListened(podcastGoalId);
                    // clear polling of YouTube videos when closing modal
                    //clearInterval(ytTimeUpdator)
                }
            });
        }

        function loadScrubber(audioPlayer) {
            var scrubber = $(audioPlayer).closest('.podEpisodeContainer').find('.slider');
            for (var i = 0; i < scrubber.length; i++) {
                scrubber[i].max = Math.floor(audioPlayer.duration)
                scrubber[i].min = 0
                scrubber[i].value = 0
            }
        }

        // updates an episodes time when the range input is clicked
        function updateTime(currentScrubber, currentPlayer, currentTime) {
            var player = $(currentScrubber).closest('.podEpisodeContainer').find('audio')[0]
            var scrubbers = $(currentPlayer).parent('.podEpisodeContainer').find('.slider')
            grabScrubbers(scrubbers, currentTime)
            player.currentTime = currentTime
        }

        $(".slider").on('mousedown', function(event) {
            scrubberClick = true

            $(this).on('mousemove', function(event) {
                var player = $(this).closest('.podEpisodeContainer').find('audio')[0]

                if (scrubberClick === true) {
                    updateTime(this, player, this.value)
                }
            })

        })

        $(".slider").on('change', function(event) {
            var player = $(this).closest('.podEpisodeContainer').find('audio')[0]
            updateTime(this, player, this.value)
        })


        $(".slider").on('mouseup', function(event) {
            scrubberClick = false
        })

        // podcast Signup form validation

        $(document).ready(function () {

            $(".emailInput").val("");
            $(document).foundation();

        });
       // var PodcastlistIcon = '<svg class="edgeSvg" style="fill:url(#grad1);vertical-align:middle" width="16px" height="16px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">< defs ><linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style="stop-color:#2D66FF;stop-opacity:1" /><stop offset="100%" style="stop-color:#00E1DC;stop-opacity:1" /></linearGradient></defs > <polygon id="path-1" points="34.453611 5.74992 51.74928 5.74992 28.7965627 57.4992 11.49984 57.4992"></polygon></svg >';

        var emailSignUpformValidation = function () {
            Podcastlist = null;
            emailSignUpPodcastList = [];
            $(".field-validation-error").html("").removeClass("field-validation-error");
            var noError = true;
            if ($(".emailSignUpPodcast").find(".emailInput").val() === "") {
                noError = false;
                //$(".emailInput").next(".field-validation-valid").addClass("field-validation-error").html("<span>*</span>");
                $(".emailSignUpPodcast .emailSignupInput").find("label").prepend("<span class='email-empty'>*</span>");
            }
            if ($(".emailSignUpPodcast").find(".emailInput").val() !== "") {
                $(".emailSignUpPodcast .emailSignupInput").find(".email-empty").remove();
                var patternEmail = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,17}$');
                //var regEmailX = new RegExp($(".emailSignUpPodcast").find(".emailInput").attr("data-val-regex-pattern"));
                var EmailBoxValue = $.trim($(".emailSignUpPodcast").find(".emailInput").val());

                if (!patternEmail.test(EmailBoxValue)) {
                    noError = false;
                    $(".emailSignUpPodcast").find(".emailInput").next("span").addClass("field-validation-error").html($("#hdnfrmemailval").val());

                } else {
                    if ($('.emailSignUpPodcast .emailCheckboxes .emailCheckboxesList input:checked').length > 0) {
                        $('.emailSignUpPodcast .emailCheckboxes .emailCheckboxesList input:checked').each(function () {
                            emailSignUpPodcastList.push($(this).parent().text());
                        });
                        Podcastlist = '<ul class="podcast-email-checklist"><li class="podcast-list" role="menuitem">'+ emailSignUpPodcastList.join('</li><li class="podcast-list">') + '</li></ul>';
                        $('.podcastUpdateListItems').html(Podcastlist);
                    }
                    $(".emailSignUpPodcast").find(".emailsection").addClass("hide");
                    $(".emailSignUpPodcast").find(".recaptchasection").removeClass("hide");
                }
            }
        };

        $('.emailSignUpPodcast .emailInput').on('keydown', function (e) {
            $(".emailSignUpPodcast").find(".emailInput").next("span").removeClass("field-validation-error email-invalid");
            $(".emailSignUpPodcast").find(".emailInput").next("span").empty();
        });

        $('#podcastSignUpModal').on('closed.zf.reveal', function () {
            playEpisode(activePodcastEpisode);
            $('#' + activePodcastEpisode).addClass('playing');
            $('.emailSignUpPodcast').find('.previousButton').trigger('click');
            $(".podcastUpdateListItems ").empty();

        });


        $('#podcastSignUpModal').on('closeme.zf.reveal', function () {
        //$(".podcastSignupLinkBrowser, .podcastSignupLinKMobile, .podcastSignupLinKLandscape").on('click', function () {
            audioPlayer.pause();
            $('.podEpisodeContainer').removeClass('playing');
            $(".emailSignUpPodcast .emailSignupInput").find(".email-empty").remove();
            $(".emailSignUpPodcast").find(".emailInput").next("span").removeClass("field-validation-error email-invalid");
            $(".emailSignUpPodcast").find(".emailInput").next("span").empty();
            $(".emailSignUpPodcast").find(".emailsection").removeClass("hide");
            $(".emailSignUpPodcast").find(".recaptchasection").addClass("hide");
            $('.emailSignUpPodcast .emailInput').val('');
            var Podcastseriesid = $('.storefront-podcast').find('.podcast-series-id').text();
            $('.emailSignUpPodcast .emailCheckboxes input:checkbox').each(function () {
                console.log(Podcastseriesid);
                if ($(this).val() == Podcastseriesid) {
                    $(this).prop('checked', true);
                }
                else {
                    $(this).prop('checked', false);
                }
                //$(this).prop('checked', false);
            });
            Podcastlist = null;
            emailSignUpPodcastList = [];
            $(".emailSignUpPodcast").find(".signupButton").keypress(function (event) {
                if (event.which == 13) {
                    emailSignUpformValidation();
                }
            });
            $(".emailSignUpPodcast").find(".signupButton").on('click', function () {
                emailSignUpformValidation();

            });
            $(".emailSignUpPodcast").find(".emailInput").keypress(function (event) {
                if (event.which == 13) {
                    event.preventDefault();
                    emailSignUpformValidation();
                    return false;
                }
            });
            $(".emailSignUpPodcast").keypress(function (event) {
                if (event.which == 13) {
                    event.preventDefault();
                    return false;
                }
            });
            $(".emailSignUpPodcast .emailCheckboxes").find(".selectAllOpt").click(function () {
                if (this.checked) {
                    $(".emailCheckboxes input[type=checkbox]").prop("checked", true);
                } else {
                    $(".emailCheckboxes input[type=checkbox]").prop("checked", false);
                }	
            });
            $(".emailSignUpPodcast").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]").click(function () {
                var numberOfCheckboxes = $(".emailSignUpPodcast").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]").length;
                var numberOfCheckboxesChecked = $(".emailSignUpPodcast").find(".emailCheckboxes .emailCheckboxesList input[type=checkbox]:checked").length;
                if (numberOfCheckboxes == numberOfCheckboxesChecked) {
                    $(".selectAllOpt").prop("checked", true);
                } else {
                    $(".selectAllOpt").prop("checked", false);
                }
            });
        });
       //End podcast Signup form validation
    }
   
}